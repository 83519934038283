import GenericService from './GenericService';
import qs from 'qs';

class UserService extends GenericService {
    login = (Email, Password) =>
        new Promise((resolve, reject) => {
            this.post('auth', {
                email: Email,
                password: Password,
            })
                .then((data) => {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('token', data.token);
                    this.tokenUpdate();
                    resolve(data.user);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    logout = () =>
        new Promise((resolve, reject) => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            this.tokenUpdate();
            resolve();
        });

    isLoggedIn = async () =>
        new Promise((resolve, reject) => {
            if (localStorage.getItem('user') != null && localStorage.getItem('token') != null) {
                resolve();
            } else {
                reject();
            }
        });

    getLoggedInUser = () => {
        const user = localStorage.getItem('user');
        if (user) return JSON.parse(user);
        else return null;
    };

    getMe = () =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`user/me`)
                .then((res) => {
                    localStorage.setItem('user', JSON.stringify(res));
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });

    getMyPoints = () =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`user/me/points`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
    getCVGData = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            // this.get(`players/last/${playerId}?limit=10`)
            this.get(`players/last/${playerId}?limit=10`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });

    getPlayerHistory = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`players/history/${playerId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
    getPlayerData = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`players/${playerId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
    getKPIData = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`players/kpi/${playerId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
        getTeamsOne = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`teams/${playerId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
        getTeamsTurnArounds = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`teams/turnarounds/${playerId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
        getTeamsSquads = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`teams/players/${playerId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
        getTeamsSeasonProgress = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`teams/history/${playerId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
        getTeamsLastTen = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`teams/last/${playerId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
    getPerformanceScaleData = (playerId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`players/scale/${playerId}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });
    updateMe = (data) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.put(`user/me`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    console.log('Error', err);
                    reject(err);
                });
        });

    forgetPassword = (data) => this.post('user/password/reset/request', data);

    resetPassword = (data) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.post('user/password/reset/confirm', data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    register = (data) =>
        new Promise((resolve, reject) => {
            this.post('user', data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    migration = (data) => {
        this.tokenUpdate();
        return this.post('migration', data);
    };

    getSearchPlayers = (data) => {
        this.tokenUpdate();
        return this.get(`search?${qs.stringify(data)}`);
    };

    compare = (playerId, data, sort) => {
        this.tokenUpdate();
        return this.post(`players/compare/${playerId}?${qs.stringify(sort)}`, data);
    };
}

const userService = new UserService();
export default userService;
