// ----------------------------------------------------------------------
export default function DataGrid(theme) {
    return {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none', // Remove default border
                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                        fontWeight: 700,
                        fontSize: 16,
                    },

                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 700,
                        fontSize: 16,
                    },
                    // border: `1px solid ${theme.palette.grey[300]}`, // Customize border color
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: theme.palette.background.fields,
                        outline: 'none',
                    },
                    ' .MuiDataGrid-footerContainer': {
                        borderTop: 'none',
                    },
                    maxHeight: '100%',
                    // '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                    //     width: '1em',
                    //     height: '1em',
                    // },
                    // '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                    //     background: theme.palette.background.paper,
                    // },
                    // '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                    //     backgroundColor: '#888',
                    // },
                    // '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                    //     background: '#555',
                    // },
                    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: theme.palette.action.tableHover,
                        // color: "red"
                    },
                    '& .pre-select': {
                        backgroundColor: `${theme.palette.primary.light} !important`,
                    },

                    '& .MuiDataGrid-columnHeader--sortable ': {
                        outline: 'none !important',
                    },
                    '& .MuiDataGrid-columnSeparator--sideRight': {
                        opacity: '0 !important',
                    },
                    '&>.MuiDataGrid-main': {
                        '&>.MuiDataGrid-columnHeaders': {
                            borderBottom: 'none',
                        },

                        '& div div div div >.MuiDataGrid-cell': {
                            borderBottom: 'none',
                        },
                    },

                    '& .MuiDataGrid-withBorder': {
                        borderRight: 'none',
                    },

                    '& .MuiDataGrid-row.Mui-selected': {
                        backgroundColor: theme.palette.action.selected,
                    },
                    '& .MuiDataGrid-cellContent': {
                        fontWeight: 300,
                    },
                },
                row: {
                    backgroundColor: theme.palette.background.fields, // Set default background color for rows
                    '&:nth-of-type(odd)': {
                        backgroundColor: theme.palette.background.paper, // Set alternative row background color
                    },
                },
                header: {
                    border: 'none', // Remove default border
                    backgroundColor: theme.palette.background.default, // Set default background color for header
                },
                hover: {
                    backgroundColor: theme.palette.action.hover, // Set hover background color
                },
            },
        },
    };
}
