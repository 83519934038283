import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
function SplineChartTeam({ chartData }) {
    const options = {
        maintainAspectRatio: false,
        tension: 0.4,
        elements: {
            point: {
                backgroundColor: 'white',
            },
        },
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                font: {
                    family: 'Poppins',
                    size: '12',
                },
            },
            legend: {
                display: false,
            },
        },
        layout: {
            padding: {
                // left: 50,   // Add padding to prevent cut-off on left
                // right: 50,  // Add padding to prevent cut-off on right
                top: 20,    // Add padding to prevent cut-off at the top
                // bottom: 50  // Add padding to prevent cut-off at the bottom
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                    
                },
                border: {
                    color: 'white',  // Add this line
                },
                ticks: {
                    autoSkip: false,
                    color: 'white',
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                    maxRotation: 50,
                    minRotation: 50,
                    // callback: function(value, index, values) {
                    //     // Show only specific labels (0, 2, 4, 6, 8)
                    //     return index % 5 === 0 ? value : '';
                    // },
                },
                offset: true,
            },

            y: {
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0)',
                },
                border: {
                    color: 'rgba(0, 0, 0, 0)',  // This will make the line at y axis transparent
                },
                ticks: {
                    display: false,
                    color: 'white',
                    font: {
                        family: 'Poppins',
                        size: '14',
                    },
                },
                offset: true,
            },
        },
    };

    return (
        <Line data={chartData} plugins={[ChartDataLabels]} options={options} />
    );
}

export default SplineChartTeam;
