import { alpha } from '@mui/material/styles';
import { SETTINGS } from 'src/config/settings';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha(SETTINGS.PRIMARY.main, 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8),
};

const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#00EFE0',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#fff',
};
const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#83F52C',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: GREY[800],
    dialog: '#20CC82',
};
const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFFF00',
    dark: '#B78103',
    darker: '#7A4F01',
    dialog: '#f1c232',
    contrastText: GREY[800],
};
const ERROR = {
    lighter: '#efb2b2',
    light: '#e06666',
    main: '#FF3131',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#fff',
    dialog: '#E52B50',
};

const GRADIENTS = {
    primary: createGradient(SETTINGS.PRIMARY.light, SETTINGS.PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main),
};

const palette = {
    common: { black: '#000', white: '#fff' },
    primary: { ...SETTINGS.PRIMARY },
    secondary: { ...SETTINGS.SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    grey: GREY,
    gradients: GRADIENTS,
    divider: GREY[500_24],
    text: { primary: '#fff', secondary: '#fff', disabled: '#fff' },
    background: {
        paper: '#07092F',
        default: '#07092F',
        neutral: GREY[200],
        locked: ERROR.lighter,
        success: SUCCESS.dialog,
        shadow: '#eeddff',
        fields: ' #0C2F8A',
    },
    charColors: [
        '#F47A1F',
        '#FDBB2F',
        '#FF6361',
        '#377B2B',
        '#7AC142',
        '#007CC3',
        '#00529B',
        '#003F5C',
        '#58508D',
        '#BC5090',
    ],
    crm: ['#8DC63F'],
    action: {
        active: GREY[600],
        hover: GREY[500_8],
        selected: GREY[500_16],
        disabled: GREY[500_80],
        disabledBackground: GREY[500_24],
        focus: GREY[500_24],
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
        tableHover: SETTINGS.PRIMARY.light,
    },
};

export default palette;
