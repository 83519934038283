/*
  Imports
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles, createStyles } from '@mui/styles';
import { Grid, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Label from 'src/components/misc/Label';
import userService from 'src/services/UserServiceClass';

/*
  Global Variables, Functions
*/

const useStyles = makeStyles((theme) =>
    createStyles({
        smallText: {
            marginLeft: theme.spacing(0.5), // Set the left margin to 8px
        },

        red: {
            // backgroundColor: '#ffc7ce', // Change the text color to red
            backgroundColor: 'red',
            // color: '#9c0006',
            color: 'white',
        },

        yellow: {
            // backgroundColor: '#ffeb9c', // Change the text color to red
            backgroundColor: 'yellow',
            color: 'black',
            // color: '#9c5700',
        },

        green: {
            // backgroundColor: '#c6efce', // Change the text color to red
            backgroundColor: '#00ff00',
            color: 'black',
            // color: '#0d6200',
        },
    }),
);

const useStyle = makeStyles({
    root: {
        '& .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordWrap: ' break-word !important',
        },
    },
});
/*
  Main Working
*/

export default ({ reload, setReload, paginationModel, setPaginationModel, setPaginationData }) => {
    //Data grid
    const navigate = useNavigate();
    const classes = useStyles();
    const classes2 = useStyle();

    const [compare, setCompare] = React.useState([]);
    const [columns, setColumns] = React.useState([
        { field: 'rank', headerName: 'Rank', minWidth: 80, flex: 1, maxWidth: 80 },
        { field: 'shortName', headerName: 'Player', minWidth: 120, flex: 1 },
        { field: 'team', headerName: 'Team', minWidth: 120, flex: 1 },
        {
            field: 'position',
            headerName: 'Position',
            minWidth: 90,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
        },
        {
            field: 'age',
            headerName: 'Age',
            minWidth: 90,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            maxWidth: 90,
        },
        {
            field: 'matches',
            headerName: 'Matches',
            minWidth: 92,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
        },
        {
            field: 'minutes',
            minWidth: 98,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            headerName: 'Avg.Mins',

            // renderHeader: () => (
            //     <div
            //         style={{
            //             overflow: 'visible',
            //             lineHeight: '1.43rem',
            //             whiteSpace: 'normal',
            //             display: 'flex',
            //             flexDirection: 'column',
            //             alignItems: 'center',
            //         }}
            //     >
            //         Mins
            //         <br />
            //         <small className={classes.smallText}> (per game)</small>
            //     </div>
            // ),
        },
        {
            field: 'CVG',
            minWidth: 90,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            headerName: 'CVG',
            renderHeader: () => (
                <div
                    style={{
                        overflow: 'visible',
                        lineHeight: '1.43rem',
                        whiteSpace: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Rating Per Game">
                        <span>CVG</span>
                    </Tooltip>
                </div>
            ),

            renderCell: (params) => {
                return (
                    <Label
                        color={'info'}
                        variant="filled"
                        sx={{
                            color: 'black',
                        }}
                    >
                        {params.value}
                    </Label>
                );
            },
        },
        {
            field: 'rating',
            minWidth: 90,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            headerName: 'KPA',
            renderCell: (params) => {
                let value = params.value;

                if (value < 5) {
                    value = 'error';
                } else if (value >= 5 && value < 10) {
                    value = 'warning';
                } else {
                    value = 'success';
                }
                return (
                    <Label color={value} variant="filled">
                        {params.value.toFixed(1)}
                    </Label>
                );
            },

            renderHeader: () => (
                <div
                    style={{
                        overflow: 'visible',
                        lineHeight: '1.43rem',
                        whiteSpace: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Key Performance Areas">
                        <span>Avg.Key</span>
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'scoring',
            minWidth: 104,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            headerName: 'Attacking',
            renderCell: (params) => {
                let value = params.value;

                if (value < 5) {
                    value = 'error';
                } else if (value >= 5 && value < 10) {
                    value = 'warning';
                } else {
                    value = 'success';
                }
                return (
                    <Label color={value} variant="filled">
                        {params.value.toFixed(1)}
                    </Label>
                );
            },

            renderHeader: () => (
                <div
                    style={{
                        overflow: 'visible',
                        lineHeight: '1.43rem',
                        whiteSpace: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Attacking">
                        <span>Attacking</span>
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'creating',
            minWidth: 104,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            headerName: 'Creating',
            renderCell: (params) => {
                let value = params.value;

                if (value < 5) {
                    value = 'error';
                } else if (value >= 5 && value < 10) {
                    value = 'warning';
                } else {
                    value = 'success';
                }
                return (
                    <Label color={value} variant="filled">
                        {params.value.toFixed(1)}
                    </Label>
                );
            },

            renderHeader: () => (
                <div
                    style={{
                        overflow: 'visible',
                        lineHeight: '1.43rem',
                        whiteSpace: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Creativity">
                        <span>Creativity</span>
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'defending',
            minWidth: 104,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            headerName: 'Defending',
            renderCell: (params) => {
                let value = params.value;

                if (value < 5) {
                    value = 'error';
                } else if (value >= 5 && value < 10) {
                    value = 'warning';
                } else {
                    value = 'success';
                }
                return (
                    <Label color={value} variant="filled">
                        {params.value.toFixed(1)}
                    </Label>
                );
            },

            renderHeader: () => (
                <div
                    style={{
                        overflow: 'visible',
                        lineHeight: '1.43rem',
                        whiteSpace: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Defensive">
                        <span>Defensive</span>
                    </Tooltip>
                </div>
            ),
        },
        {
            field: 'intensity',
            minWidth: 104,
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            headerName: 'Intensity',
            renderCell: (params) => {
                let value = params.value;

                if (value < 5) {
                    value = 'error';
                } else if (value >= 5 && value < 10) {
                    value = 'warning';
                } else {
                    value = 'success';
                }
                return (
                    <Label color={value} variant="filled">
                        {params.value.toFixed(1)}
                    </Label>
                );
            },

            renderHeader: () => (
                <div
                    style={{
                        overflow: 'visible',
                        lineHeight: '1.43rem',
                        whiteSpace: 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Intensity">
                        <span>Intensity</span>
                    </Tooltip>
                </div>
            ),
        },

        {
            field: 'winRate',
            minWidth: 90,
            align: 'center',
            flex: 1,
            headerAlign: 'center',
            headerName: 'Win %',
            valueFormatter: (params) => Math.round(params.value) + '%',
        },
    ]);

    const [loading, setLoading] = React.useState(true);

    const [sortBy, setSortBy] = React.useState('rank:asc');

    const [selected, setSelected] = React.useState([]);

    const selectedPlayer = localStorage.getItem('playerId');

    const loadPlayers = () => {
        let players = localStorage.getItem('players');
        if (players) {
            players = JSON.parse(players);
            let parsedPlayers = [...players];

            if (players.length > 11) {
                let page = paginationModel.page || 0;
                parsedPlayers = players.slice(10 * page, 10 * page + 10);
                if (selectedPlayer) {
                    if (!parsedPlayers.find((player) => player.playerId == selectedPlayer)) {
                        let p = players.find((player) => player.playerId == selectedPlayer);
                        if (p) parsedPlayers.push(p);
                    }
                    parsedPlayers.sort((a, b) => {
                        return a.rank - b.rank;
                    });
                }
            }

            let sortModel = sortBy.split(':');

            parsedPlayers.sort((a, b) => {
                if (sortModel[1] == 'asc') {
                    if (typeof a[sortModel[0]] == 'string')
                        return a[sortModel[0]].localeCompare(b[sortModel[0]]);
                    return a[sortModel[0]] - b[sortModel[0]];
                } else {
                    if (typeof a[sortModel[0]] == 'string')
                        return b[sortModel[0]].localeCompare(a[sortModel[0]]);

                    return b[sortModel[0]] - a[sortModel[0]];
                }
            });

            setCompare(parsedPlayers);
            const count =
                parsedPlayers.length >= paginationModel.pageSize
                    ? Number.MAX_SAFE_INTEGER
                    : paginationModel.page;
            setPaginationData({ loading: false, count: count, page: paginationModel.page + 1 });

            const maxLengths = {};

            for (const key in parsedPlayers[0]) {
                if (['shortName', 'team'].includes(key)) {
                    maxLengths[key] = getMaxLengthOfColumn(parsedPlayers, key);
                }
            }

            // const maxLengths = Object.keys(parsedPlayers[0]).for((key, index) => {
            //     return getMaxLengthOfColumn(parsedPlayers, key);
            // });

            if (columns.length > 0) {
                const cols = columns.map((col, index) => {
                    return {
                        ...col,
                        minWidth: maxLengths[col.field] ? maxLengths[col.field] * 9 : col.minWidth,
                    };
                });

                setColumns(cols);
            }
            setLoading(false);
        }
    };

    function getMaxLengthOfColumn(rows, column) {
        return rows.reduce((max, row) => {
            if (!row[column]) return max;
            return Math.max(max, row[column].toString().length);
        }, 0);
    }

    React.useEffect(() => {
        setLoading(true);
        loadPlayers();
    }, [sortBy]);

    React.useEffect(async () => {
        if (reload) {
            setReload(false);
            setPaginationModel({
                page: 0,
                pageSize: 10,
            });
            return;
        }

        let filters = localStorage.getItem('filters');
        if (!filters) filters = {};

        setLoading(true);

        filters = JSON.parse(filters);

        let pagesLoaded = localStorage.getItem('pagesLoaded') ?? 0;
        if (paginationModel.page > pagesLoaded) {
            try {
                let response = await userService.compare(
                    0,
                    {
                        filters,
                    },
                    {
                        page: paginationModel.page,
                        limit: paginationModel.pageSize,
                    },
                );

                for (let i = 0; i < response.length; i++) {
                    response[i].minutes = Math.floor(response[i].minutes);
                    response[i].CVG = Math.round(response[i].CVG);
                    response[i].rating = +response[i].rating;
                    response[i].scoring = +response[i].scoring;
                    response[i].creating = +response[i].creating;
                    response[i].defending = +response[i].defending;
                    response[i].intensity = +response[i].intensity;
                    response[i].winRate = +response[i].winRate;
                }
                let oldPlayers = localStorage.getItem('players');
                oldPlayers = JSON.parse(oldPlayers);

                if (oldPlayers) {
                    oldPlayers = [...oldPlayers, ...response];
                } else {
                    oldPlayers = response;
                }

                oldPlayers.sort((a, b) => a.rank - b.rank);
                //Removing duplicates
                oldPlayers = oldPlayers.filter(
                    (player, index, self) =>
                        index === self.findIndex((p) => p.playerId === player.playerId),
                );
                localStorage.setItem('players', JSON.stringify(oldPlayers));
                localStorage.setItem('pagesLoaded', paginationModel.page);
                setCompare(response);
            } catch (error) {
                console.log(error);
            }
        }
        loadPlayers();

        // timer = setInterval(() => {
        //     loadPlayers();
        // }, 1000);
        // return () => clearInterval(timer);
    }, [paginationModel.page, reload]);

    // React.useEffect(() => {
    //     if (players.length > 0) {
    //         resizeColumns();
    //     }
    // }, [players]);

    return (
        <Grid
            container
            sx={{
                // margin: 2,
                padding: 2,
            }}
        >
            <Grid item xl={6} lg={8} md={9} xs={6}>
                <DataGrid
                    sx={{
                        // '&::-webkit-scrollbar': {
                        //     width: 20,
                        // },
                        // '&::-webkit-scrollbar-track': {
                        //     backgroundColor: 'orange',
                        // },
                        // '&::-webkit-scrollbar-thumb': {
                        //     backgroundColor: 'red',
                        //     borderRadius: 2,
                        // },
                        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                            {
                                display: 'none',
                            },
                        // '.MuiTablePagination-displayedRows': {
                        //     display: 'none', // 👈 to hide huge pagination number
                        // },
                    }}
                    localeText={{
                        MuiTablePagination: {
                            labelDisplayedRows: ({ from, to, count }) =>
                                `${paginationModel.page * paginationModel.pageSize + 1} - ${
                                    paginationModel.page * paginationModel.pageSize + compare.length
                                } of ${count === Number.MAX_SAFE_INTEGER ? 'many' : count}`,
                        },
                    }}
                    disableExtendRowFullWidth={true}
                    getRowId={(row) => row.playerId}
                    rows={compare}
                    columns={columns.slice(0, 5)}
                    pageSize={compare.length}
                    autoHeight={true}
                    // headerHeight={100}
                    disableColumnFilter={true}
                    // className={classes2.root}
                    checkboxSelection={true}
                    hideFooter={!!selectedPlayer}
                    rowCount={
                        compare.length >= paginationModel.pageSize
                            ? Number.MAX_SAFE_INTEGER
                            : compare.length + paginationModel.page * paginationModel.pageSize
                    }
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    components={{
                        ColumnUnsortedIcon: () => null,
                    }}
                    disableSelectionOnClick={true}
                    onRowClick={(params) => {
                        console.log(params.row.playerId);
                        navigate(`/performance/${params.row.playerId}`);
                    }}
                    getRowClassName={(params) => {
                        if (selectedPlayer && selectedPlayer == params.row.playerId) {
                            return 'pre-select';
                        }
                    }}
                    paginationMode="server"
                    pagination={!selectedPlayer}
                    paginationModel={paginationModel}
                    onPageChange={(page) => {
                        setPaginationModel({
                            ...paginationModel,
                            page,
                        });
                    }}
                    loading={loading}
                    hideFooterPagination={true}
                    sortingMode="server"
                    selectionModel={selected}
                    onSelectionModelChange={(newSelection) => {
                        console.log(newSelection);
                        setSelected(newSelection);
                    }}
                    onSortModelChange={(newSortModel) => {
                        if (newSortModel.length > 0)
                            setSortBy(newSortModel[0].field + ':' + newSortModel[0].sort);
                    }}
                />
            </Grid>

            <Grid item xl={6} lg={4} md={3} xs={6}>
                <DataGrid
                    sx={
                        {
                            // '.MuiTablePagination-displayedRows': {
                            //     display: 'none', // 👈 to hide huge pagination number
                            // },
                        }
                    }
                    disableExtendRowFullWidth={true}
                    getRowId={(row) => row.playerId}
                    rows={compare}
                    columns={columns.slice(5)}
                    pageSize={compare.length}
                    autoHeight={true}
                    // headerHeight={100}
                    disableColumnFilter={true}
                    // className={classes2.root}
                    checkboxSelection={false}
                    hideFooter={!!selectedPlayer}
                    rowCount={compare.length}
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    components={{
                        ColumnUnsortedIcon: () => null,
                    }}
                    disableSelectionOnClick={true}
                    onRowClick={(params) => {
                        console.log(params.row.playerId);
                        navigate(`/performance/${params.row.playerId}`);
                    }}
                    getRowClassName={(params) => {
                        if (selectedPlayer && selectedPlayer == params.row.playerId) {
                            return 'pre-select';
                        }
                    }}
                    paginationMode="server"
                    pagination={!selectedPlayer}
                    paginationModel={paginationModel}
                    onPageChange={(page) => {
                        setPaginationModel({
                            ...paginationModel,
                            page,
                        });
                    }}
                    loading={loading}
                    hideFooterPagination={true}
                    sortingMode="server"
                    selectionModel={selected}
                    hideFooterSelectedRowCount={true}
                    onSortModelChange={(newSortModel) => {
                        if (newSortModel.length > 0)
                            setSortBy(newSortModel[0].field + ':' + newSortModel[0].sort);
                    }}
                />
            </Grid>
        </Grid>
    );
};
