import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import { Box, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import userService from 'src/services/UserServiceClass';
import { useParams } from 'react-router-dom';
import SplineChart from 'src/components/SplineChart';
import Table from 'src/components/table/table';
import { useNavigate } from 'react-router-dom';

function Performance() {
    const navigate = useNavigate();

    const handleClick = (id) => {
        console.log(id);
        navigate(`/performance/${id}`); // Navigate to /performance/:PlayerId
    };
    const { PlayerId } = useParams();
    const [loading, setLoading] = useState(true);
    const [playerData, setPlayerData] = useState({});
    const [playerHistory, setPlayerHistory] = useState([]);
    const [scale, setScale] = useState([]);
    const [scale1, setScale1] = useState([]);
    const [scale2, setScale2] = useState([]);
    const [players, setPlayers] = useState([]);
    const title = `Perofrmance`;
    const [performanceScaleData, setPerformanceScaleData] = useState([]);
    const [playerCVG, setPlayerCVG] = useState();
    const [CVG, setCVG] = useState([]);
    const getCVGData = () => {
        userService
            .getCVGData(PlayerId)
            .then((response) => {
                setCVG(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        userService
            .getPlayerHistory(PlayerId)
            .then((response) => {
                setPlayerHistory(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        userService
            .getPlayerData(PlayerId)
            .then((response) => {
                console.log('Player Data', response);
                setPlayerData(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
    };
    useEffect(() => {
        setLoading(true);
        getCVGData();
    }, [PlayerId]);
    useEffect(() => {
        setLoading(true);
        if (Object.keys(playerData).length !== 0) {
            setLoading(false);
        }
    }, [playerData]);
    useEffect(() => {
        const playersFromLocalStorage = JSON.parse(localStorage.getItem('players'));

        if (playersFromLocalStorage) {
            playersFromLocalStorage.map((player) => {
                if (player.playerId == PlayerId) {
                    setPlayers(player);
                }
            });
        }

        let selectedPlayer = playersFromLocalStorage.find((player) => player.playerId == PlayerId);
        setPlayerCVG(selectedPlayer.CVG);
        let top10 = playersFromLocalStorage.filter((player, index) => index < 10);
        let top10Avg = 0;

        for (let player of top10) {
            top10Avg += player.CVG;
        }

        top10Avg /= top10.length;
        top10Avg = +Math.round(top10Avg);

        playersFromLocalStorage.sort((a, b) => {
            return b.CVG - a.CVG;
        });

        let topPlayer = playersFromLocalStorage[0];

        let points = [
            {
                playerId: selectedPlayer.playerId,
                cvg: selectedPlayer.CVG,
                name: selectedPlayer.shortName,
            },
            {
                playerId: topPlayer.playerId,
                cvg: topPlayer.CVG,
                name: topPlayer.shortName,
            },
        ];
        setPerformanceScaleData(points);
        setScale(points[0]);
        setScale1(points[1]);
        setScale2(points[2]);
    }, []);
    const data = {
        labels: CVG.map((data) => data.label),
        datasets: [
            {
                label: 'Second dataset',
                data: CVG.map((data) => Math.floor(data.cvg)),
                fill: false,
                borderColor: '#00EFE0 ',
            },
        ],
    };
    const birthDateString = playerData.birthDate;

    const birthDate = new Date(birthDateString);

    const currentDate = new Date();

    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const colors = ['#FF00FF', '#83F52C'];
    const screenScale = window.devicePixelRatio;
    let topValue;
    let performanceScaleImage;
    let gridsMarginTop;
    let tableWidth;
    let splineWidth;
    if (screenScale === 1) {
        topValue = '112vh';
        performanceScaleImage = '72vh';
        gridsMarginTop = '4vh';
        tableWidth = '46.5vw';
        splineWidth = '46.5vw';
    } else if (screenScale === 1.25) {
        topValue = '115vh';
        performanceScaleImage = '72.7vh';
        gridsMarginTop = '0vh';
        tableWidth = '48.9vw';
        splineWidth = '49vw';
    }
    let playerName = `${playerData.firstName} ${playerData.lastName}`;
    let playerShortName = playerData.shortName;
    let nameCount = playerName.split(' ').length;
    let finalPlayerName = nameCount > 3 ? playerShortName : playerName;
    return (
        <Page title={title} style={{marginTop: '4vh'}}>
            {' '}
            {loading ? (
                <Grid container alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        // marginTop: '2vh',
                        fontFamily: 'Poppins',
                        // paddingTop: '1vh',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <div
                                style={{
                                    position: 'relative',
                                    paddingBottom: '65vh',
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    // Replace with the actual path to your first image
                                    src="/datagen.png"
                                    alt="First Image"
                                    style={{
                                        position: 'absolute',
                                        top: '8vh',
                                        left: '2vh',
                                        width: '21vw',
                                        height: '42vh',
                                        objectFit: 'cover',
                                        // opacity: 0.5,
                                    }}
                                />
                                <img
                                    // Replace with the actual path to your second image
                                    src="/shield_final_4_(aqua).png"
                                    alt="Second Image"
                                    style={{
                                        position: 'absolute',
                                        top: '0vh',
                                        left: '0vw',
                                        width: '40vw',
                                        height: '65vh',
                                        objectFit: 'cover',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '10vh',
                                        left: '5vw',
                                        width: '30vw',
                                        height: '30vh',
                                        display: 'flex',
                                        alignItems: 'start',
                                        justifyContent: 'start',
                                        gap: '0vw',
                                        marginLeft: '2vw',
                                        color: '#fff',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '1vh',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: '#00EFE0',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        lineHeight: '2.5vh',
                                                        fontSize: '2.8vh',
                                                    }}
                                                >
                                                    {playerCVG}
                                                </span>
                                                <span
                                                    style={{ lineHeight: '4vh', fontSize: '2vh' }}
                                                >
                                                    CVG
                                                </span>
                                            </div>
                                            <Tooltip title={playerData.team}>
                                                <img
                                                    className="img1"
                                                    src={playerData.teamImage}
                                                ></img>
                                            </Tooltip>
                                            <Tooltip
                                                title={
                                                    playerData.nationalTeam ??
                                                    playerData.country ??
                                                    playerData.passportArea
                                                }
                                            >
                                                <img
                                                    className="img1"
                                                    src={
                                                        playerData.nationalTeamImage ??
                                                        playerData.countryImage
                                                    }
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>
                                        {/* Place your avatar component here */}
                                        <img
                                            src={playerData.imageDataURL}
                                            alt="Avatar"
                                            style={{ height: '17vh', width: '9vw' }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '27.5vh',
                                        // left: leftValue,
                                        // marginLeft: '1.5vw',
                                        width: '24vw',
                                        height: '32vh',
                                        display: 'flex',
                                        alignItems: 'start',
                                        justifyContent: 'center',
                                        color: '#ffc000',
                                    }}
                                >
                                    <h4 style={{ fontSize: '2.6vh' }}>{finalPlayerName}</h4>
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '32vh',
                                        left: '0vw',
                                        right: '0vw',
                                        width: '17vw',
                                        height: '0.1vh',
                                        margin: '0vh 3.1vw',
                                        backgroundColor: 'white',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '33vh',
                                        left: '8.3vw',
                                        width: '32vw',
                                        height: '30vh',
                                        display: 'flex',
                                        alignItems: 'start',
                                        justifyContent: 'start',
                                        gap: '1vw',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '1vh',
                                            fontSize: '2vh',
                                        }}
                                    >
                                        <div>Position</div>
                                        <div>Age</div>
                                        <div>Height</div>
                                        <div>Weight</div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '1vh',
                                            fontSize: '2vh',
                                        }}
                                    >
                                        <div
                                            style={{ color: '#00EFE0' }}
                                        >{`${playerData.role}`}</div>

                                        <div style={{ color: '#00EFE0' }}>{age}</div>

                                        <div
                                            style={{ color: '#00EFE0' }}
                                        >{`${playerData.height}cm`}</div>

                                        <div
                                            style={{ color: '#00EFE0' }}
                                        >{`${playerData.weight}Kgs`}</div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '23.5vh',
                                        left: '4.7vw',
                                        width: '14vw',
                                        height: '65vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: '3vh',
                                            color: 'black',
                                            fontWeight: 'bold',
                                            lineHeight: '2vh',
                                        }}
                                    >
                                        Rank
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '3vh',
                                            color: 'black',
                                            fontWeight: 'bold',
                                            // position: 'relative',
                                            // left: '1.3vw',
                                        }}
                                    >
                                        {players.rank}
                                        <sup
                                            style={{
                                                fontSize: '1.5vh',
                                            }}
                                        >
                                            th
                                        </sup>
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ marginTop: gridsMarginTop }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    textAlign: 'center',
                                }}
                            >
                                <img
                                    src="/small_magenta_swoosh_(right).png" // Replace with the actual path to the image for the second column
                                    alt="Second Column Image"
                                    // style={{ width: '20vw', height: '1vh', margin: '16px 0' }}
                                />
                                <Typography variant="h6" style={{ margin: '1vh 0vw' }}>
                                    Performance Scale
                                </Typography>
                                <div
                                    style={{
                                        position: 'relative',
                                        marginBottom: '1vh',
                                    }}
                                >
                                    <img
                                        src="/blue_spire_(performace scale).png" // Replace with the actual path to the image with text
                                        alt="Image with Text"
                                        style={{ height: performanceScaleImage }}
                                    />
                                    {performanceScaleData.map((scale2, index) => {
                                        console.log(scale2.id); // Let's log it here too
                                        const backgroundColor = colors[index % colors.length];
                                        const scaleSVG = Math.round(scale2.cvg * 10) / 10;
                                        let topPosition = `${115 - Math.floor(scaleSVG / 10)}`;
                                        console.log('Top position', topPosition);
                                        if (topPosition > 100) {
                                            console.log('it is running');
                                            topPosition = 92;
                                        }
                                        if (topPosition < 10) {
                                            topPosition = 12;
                                        }
                                        const topPositionPercentage = `${topPosition}%`;
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    position: 'absolute',
                                                    top: topPositionPercentage,
                                                    left: '2.0vw',
                                                    transform: 'translate(-50%, -50%)',
                                                    backgroundColor: backgroundColor,
                                                    color:
                                                        backgroundColor === '#FF00FF'
                                                            ? 'white'
                                                            : 'black',
                                                    padding: '1vh 2vh',
                                                    borderRadius: '1vh',
                                                    cursor: 'pointer',
                                                    width: '10.8vw',
                                                    marginBottom: '1vh',
                                                }}
                                                onClick={() => handleClick(scale2.playerId)}
                                            >
                                                {`${scale2.name}, ${
                                                    Math.round(scale2.cvg * 10) / 10
                                                }`}
                                            </div>
                                        );
                                    })}
                                </div>
                                <img
                                    src="/small_magenta_swoosh_(left).png" // Replace with the actual path to the third image
                                    alt="Third Image"
                                    style={{ margin: '1vh 0vw' }}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ marginTop: gridsMarginTop, paddingLeft: '0vw' }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    textAlign: 'center',
                                }}
                            >
                                <img
                                    src="/large_magenta_swoosh_(right).png" // Replace with the actual path to the image for the second column
                                    alt="Second Column Image"
                                    // style={{ width: '100%', height: 'auto', margin: '16px 0' }}
                                />
                                <Typography variant="h6" style={{ margin: '1vh 0vw' }}>
                                    Performance by Position
                                </Typography>
                                <div style={{ height: '33.5vh', width: tableWidth }}>
                                    <Table props={playerHistory} />
                                </div>

                                <img
                                    src="/large_magenta_swoosh_(left).png" // Replace with the actual path to the image with text
                                    alt="Image with Text"
                                    // style={{ width: '100%', height: 'auto' }}
                                />
                                <Typography variant="h6" style={{ margin: '1vh 0vw' }}>
                                    {/* Performance Last 10 Matches (CVG) */}
                                    Performance Tracker – Last 10 Matches (CVG)
                                </Typography>
                                <div style={{ height: '33.5vh', width: splineWidth }}>
                                    <SplineChart chartData={data} />
                                </div>

                                <img
                                    src="/large_magenta_swoosh_(right).png" // Replace with the actual path to the image for the second column
                                    alt="Second Column Image"
                                    style={{ margin: '1vh 0vw' }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            )}
            <div
                style={{
                    position: 'absolute',
                    top: topValue,
                    color: 'white',
                    left: '0vw',
                    right: '0vw',
                    width: '95.5vw',
                    height: '0.1vh',
                    margin: '0vh 2.15vw',
                    backgroundColor: 'rgba(255, 255, 255, 255)',
                }}
            />
        </Page>
    );
}

export default Performance;
