import palette from 'src/theme/palette';
import {
    AppBar,
    Box,
    IconButton,
    Stack,
    Toolbar,
    FormControlLabel,
    Checkbox,
    Button,
    Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { ExportIcon, StoreIcon } from 'src/config/icons';
import { RouteLogout } from 'src/config/routes';

// ----------------------------------------------------------------------

export default function NavBarButtons() {
    

    return (
        <>
            <Stack
                direction="row-reverse"
                alignItems="center"
                spacing={{ xs: 1, sm: 1, md: 1 }}
                sx={{ marginTop: 6 }}
            >
                <Button
                    sx={{
                        color: 'black',
                        borderRadius: '5px',
                        padding: '9px 32px 9px 33px',
                        fontSize: '16px',
                        backgroundColor: palette.secondary.main,
                        ':hover': {
                            backgroundColor: palette.secondary.dark,
                        },
                    }}
                    // startIcon={<Icon icon={ExportIcon} />}
                    href={RouteLogout}
                >
                    LOGOUT
                </Button>
                {/* <Box>
                    <FormControlLabel
                        sx={{
                            backgroundColor: palette.primary.main,
                            color: 'white',

                            pr: 5,
                            borderRadius: '5px',
                            ':hover': {
                                backgroundColor: palette.primary.dark,
                            },
                        }}
                        control={
                            <Checkbox
                                sx={{
                                    color: 'white',
                                }}
                            />
                        }
                        label={<Typography style={{ fontWeight: 700 }}>COMPARE</Typography>}
                    />
                </Box> */}
            </Stack>
        </>
    );
}
