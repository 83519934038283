/*
  Imports
*/
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BarChart from 'src/components/BarChart';
import RadarChart from 'src/components/RadarChart';
import SplineChart from 'src/components/SplineChart';
import StackedBarChart from 'src/components/StackedBar';
import userService from 'src/services/UserServiceClass';
import Page from '../components/Page';
import { CircularProgress } from '@mui/material';
import './chart.css';

import Table from 'src/components/table/table';

/*
  Main Working
*/
export default () => {
    const { PlayerId } = useParams();
    const [players, setPlayers] = useState([]);
    const [playerData, setPlayerData] = useState({});
    const [CVG, setCVG] = useState([]);
    const [KPI, setKPI] = useState({});
    const [scale, setScale] = useState([]);
    const [scale1, setScale1] = useState([]);
    const [scale2, setScale2] = useState([]);
    const [performanceScale, setPerformanceScale] = useState([]);
    const [playerHistory, setPlayerHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const getCVGData = () => {
        userService
            .getCVGData(PlayerId)
            .then((response) => {
                setCVG(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        userService
            .getKPIData(PlayerId)
            .then((response) => {
                setKPI(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        // userService
        //     .getPerformanceScaleData(PlayerId)
        //     .then((response) => {
        //         console.log('Performance Scale ', response);
        //         setPerformanceScale(response.bars);
        //         setScale(response.points[0]);
        //         setScale1(response.points[1]);
        //         setScale2(response.points[2]);
        //     })
        //     .catch((err) => {
        //         if (err.response) console.log(err);
        //     });


        userService
            .getPlayerHistory(PlayerId)
            .then((response) => {
                setPlayerHistory(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
        userService
            .getPlayerData(PlayerId)
            .then((response) => {
                setPlayerData(response);
            })
            .catch((err) => {
                if (err.response) console.log(err);
            });
    };
    useEffect(() => {
        setLoading(true);
        getCVGData();
    }, [PlayerId]);
    useEffect(() => {
        setLoading(true);
        if (Object.keys(playerData).length !== 0) {

            setLoading(false);
        }
    }, [playerData]);
    useEffect(() => {
        const playersFromLocalStorage = JSON.parse(localStorage.getItem('players'));

        if (playersFromLocalStorage) {
            playersFromLocalStorage.map((player) => {
                if (player.playerId == PlayerId) {

                    setPlayers(player);
                }
            });
        }

        let selectedPlayer = playersFromLocalStorage.find((player) => player.playerId == PlayerId);

        let top10 = playersFromLocalStorage.filter((player, index) => index < 10);
        let top10Avg = 0;

        for (let player of top10) {
            top10Avg += player.CVG;
        }

        top10Avg /= top10.length;
        top10Avg = +Math.round(top10Avg);

        playersFromLocalStorage.sort((a, b) => {
            return b.CVG - a.CVG;
        });

        let topPlayer = playersFromLocalStorage[0];

        let points = [{
            playerId: selectedPlayer.playerId,
            cvg: selectedPlayer.CVG,
            name: selectedPlayer.shortName,
        },
        {
            playerId: 0,
            cvg: top10Avg,
            name: 'Top 10 CVG',
        },
        {
            playerId: topPlayer.playerId,
            cvg: topPlayer.CVG,
            name: topPlayer.shortName,
        }]

        setPerformanceScale([
            150,
            250,
            600
        ]);
        setScale(points[0]);
        setScale1(points[1]);
        setScale2(points[2]);

    }, []);
    const navigate = useNavigate();

    const [user, setUser] = useState();
    const best = () => {
        const playersFromLocalStorage = JSON.parse(localStorage.getItem('players'));
        if (playersFromLocalStorage) {
            const maxRating = playersFromLocalStorage.reduce((max, player) => {
                return Math.max(max, player.rating);
            }, -Infinity);
            return maxRating;
        }
    };
    const average = () => {
        return players.rating;
    };
    const userData = {
        labels: ['Attacking', 'Creativity', 'Defensive', 'Intensity'],
        datasets: [
            {
                barPercentage: 1.2,
                categoryPercentage: 0.5,
                label: 'Key Events Per Game',
                data: [
                    Math.round(players.scoring * 10) / 10,
                    Math.round(players.creating * 10) / 10,
                    Math.round(players.defending * 10) / 10,
                    Math.round(players.intensity * 10) / 10,
                ],
                backgroundColor: [
                    'rgb(0, 255, 0)',
                    'rgb(233,4,235)',
                    'rgb(235,234,8)',
                    'RGB(0 176 240)',
                ],
            },
        ],
    };
    const data = {
        labels: CVG.map((data) => data.label),
        datasets: [
            {
                label: 'Second dataset',
                data: CVG.map((data) => Math.floor(data.cvg)),
                fill: false,
                borderColor: 'rgb(156,102,252)',
            },
        ],
    };
    const Stackeddata = {
        labels: [''],
        datasets: [
            {
                data: [performanceScale[0]],
                backgroundColor: 'rgba(246,2,3,0.3)',
                borderColor: 'rgba(246,2,3,255)',
                borderWidth: 2,
                borderSkipped: 'top',
            },
            {
                backgroundColor: 'rgba(246,186,3,0.3)',
                borderColor: 'rgba(246,186,3,255)',
                borderWidth: 2,
                borderSkipped: 'top',
                data: [performanceScale[1]],
            },
            {
                backgroundColor: 'rgba(2,170,79,0.3)',
                borderColor: 'rgba(2,170,79,255)',
                borderWidth: 2,
                borderSkipped: 'top',
                data: [performanceScale[2]],
            },
        ],
    };
    const Radardata = {
        labels: Object.keys(KPI),
        datasets: [
            {
                label: 'Second dataset',
                data: Object.values(KPI),
                fill: true,
                backgroundColor: 'rgb(91,155,213)',
                borderColor: 'white',
                pointRadius: 0,
                borderWidth: 2,
            },
        ],
    };
    const title = `Filter`;
    const birthDateString = playerData.birthDate;

    const birthDate = new Date(birthDateString);

    const currentDate = new Date();

    const age = currentDate.getFullYear() - birthDate.getFullYear();

    return (
        <Page title={title}>
            <br />
            {loading ? (
                <Grid container alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '0.675rem',
                        fontFamily: 'Poppins',
                    }}
                >
                    <Grid container spacing={1} lg={10}>
                        <Grid item lg={6}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    backgroundColor: '#203864',
                                    height: '6vh',
                                }}
                            >
                                <img className="img" src={playerData.imageDataURL}></img>
                                <h4 className="sname"> {playerData.shortName}</h4>
                                <h4 className="league">
                                    {`${playerData.firstName} ${playerData.lastName} -  ${playerData.country}`}
                                </h4>
                                <img className="img1" src={playerData.teamImage}></img>
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    backgroundColor: '#203864',
                                    height: '6vh',
                                }}
                            >
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <h4 className="sname">Position:</h4>
                                    <h4 className="striker">{playerData.role}</h4>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <h4 className="sname">Age:</h4>
                                    <h4 className="striker">{age}</h4>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <h4 className="sname">Height:</h4>
                                    <h4 className="striker">{`${playerData.height} cm`}</h4>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <h4 className="sname">Weight:</h4>
                                    <h4 className="striker">{`${playerData.weight} Kgs`}</h4>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={8}>
                            <div className="bar">
                                <BarChart chartData={userData} best={best} average={average} />
                            </div>
                        </Grid>
                        <Grid item lg={4}>
                            <div className="radar">
                                <RadarChart chartData={Radardata} />
                            </div>
                        </Grid>
                        <Grid item lg={5}>
                            <div className="spline">
                                <SplineChart chartData={data} />
                            </div>
                        </Grid>
                        <Grid item lg={7}>
                            <Table props={playerHistory} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} lg={2}>
                        <Grid item lg={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#203864',
                                    height: '6vh',
                                    gap: '5px',
                                }}
                            >
                                <h4 className="rank">Position Rank:</h4>
                                <h4>{`#${players.rank}`}</h4>
                            </div>
                        </Grid>
                        <Grid item lg={12}>
                            <div className="perform1">
                                <StackedBarChart
                                    chartData={Stackeddata}
                                    first={scale1}
                                    second={scale2}
                                    third={scale}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Page>
    );
};
