// import * as React from 'react';
// import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// import { Icon } from '@iconify/react';
// import { AppBar, Box, Button, Grid, IconButton, Stack, Toolbar, Typography } from '@mui/material';
// import { alpha, styled } from '@mui/material/styles';
// import { Link } from '@mui/material';
// import PropTypes from 'prop-types';
// import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import HeaderLogo from 'src/components/HeaderLogo';
// import { MHidden } from '../components/@material-extend';
// import userService from '../services/UserServiceClass';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
// import Grow from '@mui/material/Grow';
// import Paper from '@mui/material/Paper';
// import Popper from '@mui/material/Popper';
// import MenuItem from '@mui/material/MenuItem';
// import MenuList from '@mui/material/MenuList';
// import {
//     RouteDiscord,
//     RouteHome,
//     RouteLastChaos,
//     RouteLogin,
//     RouteNews,
//     RouteRegister,
//     RouteShaiya,
//     RouteStore,
// } from 'src/config/routes';

// const DRAWER_WIDTH = '100%';
// const APPBAR_MOBILE = 64;
// const APPBAR_DESKTOP = 92;

// const RootStyle = styled(AppBar)(({ theme }) => ({
//     boxShadow: 'none',
//     backdropFilter: 'blur(6px)',
//     WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
//     backgroundColor: alpha(theme.palette.background.default, 0.72),
//     // backgroundColor: 'red',
//     [theme.breakpoints.up('lg')]: {
//         width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
//     },
// }));

// const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
//     minHeight: APPBAR_MOBILE,
//     [theme.breakpoints.up('lg')]: {
//         minHeight: APPBAR_DESKTOP,
//         padding: theme.spacing(0, 5),
//     },
//     borderBottom: 1,
//     borderColor: theme.palette.error,
// }));

// DashboardNavbar.propTypes = {
//     onOpenSidebar: PropTypes.func,
//     isAdmin: PropTypes.bool,
// };

// export default function DashboardNavbar({ onOpenSidebar }) {
//     const navigator = useNavigate();
//     const [user, setUser] = useState({ username: null });
//     const [open, setOpen] = React.useState(false);
//     const anchorRef = React.useRef(null);

//     const handleToggle = () => {
//         setOpen((prevOpen) => !prevOpen);
//     };

//     const handleClose = (event) => {
//         if (anchorRef.current && anchorRef.current.contains(event.target)) {
//             return;
//         }

//         setOpen(false);
//     };

//     function handleListKeyDown(event) {
//         if (event.key === 'Tab') {
//             event.preventDefault();
//             setOpen(false);
//         } else if (event.key === 'Escape') {
//             setOpen(false);
//         }
//     }

//     const prevOpen = React.useRef(open);
//     React.useEffect(() => {
//         if (prevOpen.current === true && open === false) {
//             anchorRef.current.focus();
//         }

//         prevOpen.current = open;
//     }, [open]);

//     const handleLogout = () => {
//         navigator('/logout', { replace: true });
//     };

//     useEffect(() => {
//         setUser(userService.getLoggedInUser());
//         // console.log('user', user);
//         //Rank based display here
//     }, []);

//     const linkStyle = {
//         color: 'text.primary',

//         '&:hover': {
//             color: 'primary.main',
//             cursor: 'pointer',
//         },
//     };
//     return (
//         <RootStyle>
//             <ToolbarStyle
//                 sx={{
//                     borderBottom: 0.5,
//                     borderColor: '#232629',
//                     backgroundColor: 'black',
//                 }}
//             >
//                 {user?.username ? (
//                     <>
//                         <MHidden width="lgUp">
//                             <IconButton
//                                 onClick={onOpenSidebar}
//                                 sx={{ mr: 1, color: 'text.primary' }}
//                             >
//                                 <Icon icon={menu2Fill} />
//                             </IconButton>
//                             <Box sx={{ flexGrow: 1 }} />
//                             <Stack direction="row" alignItems="center" spacing={{ lg: 2, md: 2 }}>
//                                 <Typography variant="subtitle1">{user?.username}</Typography>
//                             </Stack>
//                         </MHidden>
//                         <MHidden width="lgDown">
//                             <Box sx={{ flexGrow: 0.8, pl: '9rem' }}>
//                                 <Grid
//                                     container
//                                     direction="row"
//                                     justifyContent="flex-start"
//                                     alignItems="center"
//                                 >
//                                     <Grid item md={1.5}>
//                                         <HeaderLogo />
//                                     </Grid>
//                                     <Grid item md={1.5}>
//                                         <Link href={RouteHome} underline="none" sx={linkStyle}>
//                                             <Typography>HOME</Typography>
//                                         </Link>
//                                     </Grid>
//                                     <Grid item md={1.5}>
//                                         <Link href={RouteNews} underline="none" sx={linkStyle}>
//                                             <Typography>NEWS</Typography>
//                                         </Link>
//                                     </Grid>
//                                     <Grid item md={1.5}>
//                                         <Link
//                                             ref={anchorRef}
//                                             id="composition-button"
//                                             aria-controls={open ? 'composition-menu' : undefined}
//                                             aria-expanded={open ? 'true' : undefined}
//                                             aria-haspopup="true"
//                                             onClick={handleToggle}
//                                             underline="none"
//                                             sx={linkStyle}
//                                         >
//                                             <Typography>GAMES</Typography>
//                                         </Link>
//                                         <Popper
//                                             open={open}
//                                             anchorEl={anchorRef.current}
//                                             role={undefined}
//                                             placement="bottom-start"
//                                             transition
//                                             disablePortal
//                                         >
//                                             {({ TransitionProps, placement }) => (
//                                                 <Grow
//                                                     {...TransitionProps}
//                                                     style={{
//                                                         transformOrigin:
//                                                             placement === 'bottom-start'
//                                                                 ? 'left top'
//                                                                 : 'left bottom',
//                                                     }}
//                                                 >
//                                                     <Paper>
//                                                         <ClickAwayListener
//                                                             onClickAway={handleClose}
//                                                         >
//                                                             <MenuList
//                                                                 id="composition-menu"
//                                                                 aria-labelledby="composition-button"
//                                                                 onKeyDown={handleListKeyDown}
//                                                                 variant="menu"
//                                                             >
//                                                                 <MenuItem
//                                                                     onClick={handleClose}
//                                                                     component={Link}
//                                                                     href={RouteShaiya}
//                                                                     target="_blank"
//                                                                     sx={linkStyle}
//                                                                 >
//                                                                     SHAIYA
//                                                                 </MenuItem>
//                                                                 <MenuItem
//                                                                     onClick={handleClose}
//                                                                     component={Link}
//                                                                     href={RouteLastChaos}
//                                                                     target="_blank"
//                                                                     sx={linkStyle}
//                                                                 >
//                                                                     LAST CHAOS
//                                                                 </MenuItem>
//                                                             </MenuList>
//                                                         </ClickAwayListener>
//                                                     </Paper>
//                                                 </Grow>
//                                             )}
//                                         </Popper>
//                                     </Grid>
//                                     <Grid item md={1.5}>
//                                         <Link
//                                             href={RouteDiscord}
//                                             target="_blank"
//                                             underline="none"
//                                             sx={linkStyle}
//                                         >
//                                             <Typography>DISCORD</Typography>
//                                         </Link>
//                                     </Grid>
//                                     <Grid item md={1.5}>
//                                         <Link href={RouteStore} underline="none" sx={linkStyle}>
//                                             <Typography>STORE</Typography>
//                                         </Link>
//                                     </Grid>
//                                 </Grid>
//                             </Box>
//                         </MHidden>
//                     </>
//                 ) : (
//                     <>
//                         <MHidden width="lgUp">
//                             <IconButton
//                                 onClick={onOpenSidebar}
//                                 sx={{ mr: 1, color: 'text.primary' }}
//                             >
//                                 <Icon icon={menu2Fill} />
//                             </IconButton>
//                             <Box sx={{ flexGrow: 1 }} />
//                             <Stack
//                                 direction="row"
//                                 alignItems="left"
//                                 spacing={{ xs: 2, lg: 2, md: 2 }}
//                             >
//                                 <Button
//                                     fullWidth
//                                     color="inherit"
//                                     variant="outlined"
//                                     onClick={() => navigator(RouteLogin, { replace: false })}
//                                     style={{
//                                         backgroundColor: '#E94535',
//                                         height: 25,
//                                         alignSelf: 'center',
//                                         width: 20,
//                                     }}
//                                 >
//                                     Login
//                                 </Button>
//                                 <Button
//                                     fullWidth
//                                     color="inherit"
//                                     variant="outlined"
//                                     onClick={() => navigator(RouteRegister, { replace: false })}
//                                     style={{
//                                         // backgroundColor: '#E94535',
//                                         maxWidth: 25,
//                                         height: 25,
//                                         alignSelf: 'center',
//                                         width: 20,
//                                     }}
//                                 >
//                                     Register
//                                 </Button>
//                             </Stack>
//                         </MHidden>
//                         <MHidden width="lgDown">
//                             <Box sx={{ flexGrow: 0.8, pl: '9rem' }}>
//                                 <Grid
//                                     container
//                                     direction="row"
//                                     justifyContent="flex-start"
//                                     alignItems="center"
//                                 >
//                                     <Grid item md={1}>
//                                         <HeaderLogo />
//                                     </Grid>
//                                     <Grid item md={1}>
//                                         <Link href={RouteHome} underline="none" sx={linkStyle}>
//                                             <Typography>HOME</Typography>
//                                         </Link>
//                                     </Grid>
//                                     <Grid item md={1}>
//                                         <Link
//                                             href={RouteDiscord}
//                                             target="_blank"
//                                             underline="none"
//                                             sx={linkStyle}
//                                         >
//                                             <Typography>DISCORD</Typography>
//                                         </Link>
//                                     </Grid>
//                                 </Grid>
//                             </Box>
//                         </MHidden>
//                     </>
//                 )}
//                 <MHidden width="lgDown">
//                     {user?.username ? (
//                         <>
//                             <Stack direction="row" alignItems="center" spacing={{ lg: 2, md: 2 }}>
//                                 <Typography variant="subtitle1">{user.username}</Typography>
//                                 <Button
//                                     fullWidth
//                                     color="inherit"
//                                     variant="outlined"
//                                     onClick={handleLogout}
//                                     style={{ backgroundColor: '#E94535' }}
//                                 >
//                                     Logout
//                                 </Button>
//                             </Stack>
//                         </>
//                     ) : (
//                         <>
//                             <Stack direction="row" alignItems="left" spacing={{ lg: 2, md: 2 }}>
//                                 <Button
//                                     fullWidth
//                                     color="inherit"
//                                     variant="outlined"
//                                     onClick={() => navigator(RouteLogin, { replace: false })}
//                                     style={{ backgroundColor: '#E94535' }}
//                                 >
//                                     Login
//                                 </Button>
//                                 <Button
//                                     fullWidth
//                                     color="inherit"
//                                     variant="outlined"
//                                     onClick={() => navigator(RouteRegister, { replace: false })}
//                                 >
//                                     Register
//                                 </Button>
//                             </Stack>
//                         </>
//                     )}
//                 </MHidden>
//             </ToolbarStyle>
//         </RootStyle>
//     );
// }

import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import { AppBar, Box, IconButton, Stack, Toolbar, FormControlLabel, Checkbox } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Logo from 'src/components/Logo';
import { SETTINGS } from 'src/config/settings';
import { MHidden } from '../components/@material-extend';
import palette from 'src/theme/palette';
import NavBarButtons from 'src/components/NavBarButtons';
import { useLocation, useNavigate } from 'react-router-dom';
// import AccountPopover from './AccountPopover';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        // padding: theme.spacing(0, 5),
    },
}));

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
    isAdmin: PropTypes.bool,
};

export default function DashboardNavbar({ onOpenSidebar }) {
    const location = useLocation();
    const navigate = useNavigate();
    const isFirstPage = () => {
        return location.pathname === '/find';
    };

    return (
        <RootStyle position="fixed">
            <ToolbarStyle 
            sx={{ width: '95.5vw',margin: '0vh 1.3vw',paddingLeft: '0px !important',paddingRight: '0px !important' }}
            >
                <MHidden width="lgUp">
                    <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden>
                <Box sx={{ ml: '-0.4vw', py: '0.5vh' }} onClick={()=>navigate('/find')}>
                    <Logo path={SETTINGS.LogoImage2}
                     sx={{ width: '22vw', margin:"0px"}}
                      />
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                {isFirstPage() && <NavBarButtons />}
            </ToolbarStyle>
            <Box sx={{
                    height: '0.1vh', 
                    backgroundColor: 'white',
                    width: '96vw',
                    ml:'1.8vw'
                    }} />
        </RootStyle>
    );
}
